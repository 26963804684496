<template>
  <el-aside class="aside left_aside_wrap" style="width:250px">
    <div class="left_aside">
      <h4 class="title">标准分类</h4>
      <div class="left-aside-items" v-for="(item, i) in leftList" :key="i">
        <div
          class="left-aside-logo"
          :class="activeParentId == item.id && !activeIndex ? 'active' : ''"
          @click="$emit('regetData', item, '', item.id)"
        >
          <img :src="item.iconPicUrl || iconPicUrl" alt="logo" />{{ item.name }}
        </div>
        <div class="left-aside-child-box" v-if="item.children && item.children.length">
          <div
            v-for="(ops, j) in item.children"
            :key="j"
            @click="$emit('regetData', ops, ops.id, item.id)"
            class=""
            :class="activeParentId == item.id && activeIndex == ops.id ? 'active' : ''"
          >
            {{ ops.name }}
          </div>
        </div>
      </div>
    </div>
  </el-aside>
</template>

<script>
const iconPicUrl = 'https://www.quanqingkeji.com/cos/download/imgDownload/202308/quanbu.png'
export default {
  name: 'StandardLeftAside',
  props: ['leftList', 'activeParentId', 'activeIndex'],
  data() {
    return {
      iconPicUrl
    }
  },
  methods: {}
}
</script>

<style src="../scss/newStandard.scss" lang="scss" scoped></style>
