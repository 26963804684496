import { render, staticRenderFns } from "./quanqingAbout.vue?vue&type=template&id=205aa149&scoped=true&"
import script from "./quanqingAbout.vue?vue&type=script&lang=js&"
export * from "./quanqingAbout.vue?vue&type=script&lang=js&"
import style0 from "./quanqingAbout.vue?vue&type=style&index=0&id=205aa149&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "205aa149",
  null
  
)

export default component.exports