<template>
  <div class="package-for-component-container">
    <div class="first-line">
      {{ info.name }}
      <img
        v-if="canEditPackage"
        src="/img/img_edit.png"
        alt="edit"
        class="el-icon-edit"
        style="margin-left:4px"
        @click="$emit('edit')"
      />
    </div>
    <div class="second-line" v-if="info.items">
      <span v-for="(item, index) in info.items" :key="index">
        {{ item.name }} <i v-if="item.tag">({{ item.tag }} )</i></span
      >
    </div>
  </div>
</template>

<script>
export default {
  name: 'packageForComponent',
  props: {
    info: {
      type: Object,
      default() {
        return {
          name: '',
          items: []
        }
      }
    },
    canEditPackage: {
      type: Boolean,
      default() {
        return false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.package-for-component-container {
  width: 235px;
  margin-right: 10px;
  margin-bottom: 20px;

  .first-line {
    margin-bottom: 10px;
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    line-height: 25px;
    display: flex;
    align-items: center;
  }

  .second-line {
    line-height: 30px;
    span {
      display: inline-block;
      min-width: 60px;
      text-align: center;
      height: 24px;
      border-radius: 12px;
      border: 1px solid #5ad3cd;
      font-size: 13px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #5ad3cd;
      line-height: 22px;
      padding: 0 12px;
      margin-right: 8px;
    }
  }
}

.el-icon-edit {
  cursor: pointer;
}
</style>
