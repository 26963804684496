export const aboutContent = `
<p>
  全倾平台隶属于北京全倾科技有限公司，分为全倾网站（https://www.quanqingkeji.com）与全倾app。
</p>
<p>
  全倾平台致力于为全国企业客户提供以二三线的低价格获取互联网级品质的技术外包及项目开发服务。
  全倾平台入驻了数以百千计的技术外包公司、兼职个人及兼职团队。技术外包公司有着丰富的项目案例及快速的项目开发速度；
  兼职个人则是一方面来自于工作在百度、腾讯、阿里、美团、头条等知名互联网公司的技术专家，拥有着较深度的专业技术经验，
  另一方面来自于全国的技术员工，拥有着丰富的开发经验、与相比一线更有竞争力的人力成本优势。兼职个人组成兼职团队后，
  全倾平台上的技术公司和兼职团队即可承接客户的项目开发需求。全倾出台了几十项的技术标准，出品了多款技术产品，
  为平台上产出的项目进行质量检验及效果验证，保障您的项目质量不留死角，顺利上线并稳定运行。
</p>
<p>
  北京全倾科技有限公司成立于2020年，由原美团车票及商家金融的技术负责人离职后创立。致力于解决全国中小企业的技术痛点及人力成本，
  中大型企业的技术升级需求及人力不足等问题。
</p>`
