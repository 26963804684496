export const companyContent = `
<p>
    成为全倾平台的推广合作伙伴，推荐客户在全倾平台下单并签约。客户签约的首单项目，全倾因此项目产生的收入，您公司可从此收入中分成约40%。并且，在全倾与客户的后续合作中，当客户在全倾的成交额达到10万、100万、1000万时，您公司可额外获得约2000､1.5万、10万元的分成。（具体分成比例及金额，以签约合同为准）
</p>
<p>
    您的推广方式一般可分为以下几种之一：在您网站（app、小程序）中添加小广告位、或提交项目需求小入口、或制作单独的外包项目承接网页说明；向您的企业客户进行单独的人工沟通与推荐；
</p>
<p>
    若您司有兴趣成为全倾推广合作伙伴，可将您司邮箱及电话号码发送至contact@quanqingkeji.com，全倾会有运营人员与您联系，配置后续合作事宜。
</p>
`
