export const connectionContent = `
<p>
    与全倾取得联系，请发邮件至contact@quanqingkeji.com。
</p>
<p>
若您为企业客户，有项目开发需求，可点击网站右上角的“发布需求”按钮即可；若您为技术外包公司，想入驻全倾平台，可点击网站右上角的“入驻”按钮，并选择身份为“公司”继续填写资料即可。
若您为兼职个人，想入驻全倾平台，可点击网站右上角的“入驻”按钮，并选择身份为“个人”继续填写资料即可。
若您想成为全倾的推广合作伙伴，可点击 <a href="https://www.quanqingkeji.com/#/companyCommission">公司推广分佣</a> 或 <a href="https://www.quanqingkeji.com/#/employCommission">个人推广分佣</a> 链接了解更多。
</p>
`
