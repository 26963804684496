<template>
  <el-container class="right rigit-tag-container">
    <div class="tag-box" v-if="item.className">{{ item.className }}</div>
    <el-aside width="180px" class="right-aside">
      <h4 class="rigit-tag-title">{{ item.name }}</h4>

      <p class="right-aside-choose-detection ">
        检测 <span class="quantity">￥{{ setPriceNum(item.detectPrice) }}</span>
      </p>
      <p class="right-aside-choose-guarantee ">
        保障 <span class="quantity">￥{{ setPriceNum(item.ensurePrice) }}</span>
      </p>
      <button v-if="canBuyButton" class="right-aside-choose-button " @click="$emit('addBuyList', item.id)">
        选购
      </button>
    </el-aside>
    <el-main style="padding:0">
      <div class="top-box">
        <p class="right-main" v-if="item.meaning">
          <span><i></i> 含义 ： </span><b>{{ item.meaning }}</b>
        </p>
        <p class="right-main" v-if="item.notReachInfluence">
          <span><i></i> 不达标影响 ： </span><b>{{ item.notReachInfluence }}</b>
        </p>
      </div>
      <div class="top-box" style="margin-top:15px;">
        <p class="right-main" v-for="i in 5" :key="i" v-if="item[`level${i}`]">
          <span style="display:flex;"
            ><i></i> <el-rate :value="i" disabled :max="i"> </el-rate>
            <pre> ：</pre>
          </span>
          <b> {{ item[`level${i}`] }}</b>
        </p>
      </div>
      <div class="button-box">
        <!-- <p>
                    <button v-if="item.productButtonVisible" class="right-aside-button " @click="toQuanqingProduct(item)">
                        查找产品
                    </button>
                </p> -->
        <button v-if="item.hasCourse" class="right-aside-button " @click="toVideoPage(item.id)">
          学习视频
        </button>
        <button v-if="item.reachMethod" class="right-aside-button " @click="$emit('showFun', item.reachMethod)">
          达标方法
        </button>
        <button v-if="item.employeeButtonVisible" class="right-aside-button " @click="toPersonalPage(item)">
          查找专家
        </button>
      </div>
    </el-main>
  </el-container>
</template>

<script>
import {setPriceNum} from '@/utils/index'
import {getStandardgetStandardEmployeeDomainsApi} from '../../../api/standard'
export default {
  name: 'StandardRightAside',
  props: ['item', 'canBuyButton'],
  methods: {
    setPriceNum,
    // toQuanqingProduct(row) {
    //   this.$router.push({
    //     path: '/quanqingProduct',
    //     query: {id: row.classId}
    //   })
    // },
    toPersonalPage(row) {
      getStandardgetStandardEmployeeDomainsApi({standardId: row.id}).then(res => {
        log.JSON(res, '标准分类对应的擅长领域')
        if (res.data) {
          this.$router.push({
            path: '/personal',
            query: {id: res.data[0]}
          })
        }
      })
    },
    toVideoPage(id) {
      this.$router.push({
        path: '/techVideo',
        query: {
          standardId: id
        }
      })
    }
  }
}
</script>

<style src="../scss/newStandard.scss" lang="scss" scoped></style>
